<script>
import Vue from 'vue';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {email, required} from "vuelidate/lib/validators";
// Import HTTP
import HttpRequest from "@/http/HttpRequest"
//Common HTTP object for managing request response
let httpRequest = new HttpRequest()
// Import component
import Loading from 'vue-loading-overlay';
import Preference from "@/data/Preference";
let preference = new Preference();

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Role from "@/data/Roles";

let roleChecker = new Role();

// Init plugin
Vue.use(Loading);

export default {

  data() {
    return {
      isLoading: false,
      submitted: false,
      isJiraSyncing: false,
      jiraIsLoading: false,
      isJiraIntegrated: false,
      jiraHostUrl: null,
      consumer_key: null,
      private_key: null,
      oauthToken: null,
      oauthTokenSecret: null,
      title: "Company",
      role: preference.getData(Preference.KEY_ROLE_ID),
      access_property: "Company",
      items: [
        {
          text: "WORKANY",
          to: '/'
        },
        {
          text: "Workspace",
          active: true
        }
      ],
      oauth_verifier: null,
      email: "",
      orgOwnerEmail: "",
      error: null,
      tryingToReset: false,
      isResetError: false,
      work_space_id: "",
      workspace_name: "",
      company_name: "",
      company_logo_url: "",
      company_logo: "",
      company_email: "",
      active_package: 1,
      user_id: "",
      user_email: "",
      org_address: "",
      org_domain: "",
    }
  },
  validations: {
    company_name: {required},
    company_email: {required, email},
  },
  components: {
    Layout,
    PageHeader
  },

  methods: {
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id, resource, action);
    },
    getWorkSpaceDetails() {
      let _this = this
      _this.isLoading = true
      return httpRequest.getWorkspaceDetails().then(function (response) {
        _this.isLoading = false
        if (response.success) {
          // _this.company_logo_url = response.data.company_logo
          _this.workspace_name = response.data.work_space_name
          _this.company_name = response.data.company_name
          _this.company_email = response.data.company_email
          _this.active_package = response.data.subscription_id
          _this.work_space_id = response.data.workspace_id
          _this.org_domain = response.data.work_space
        }
      })
    },

    async onChange(e) {
      const file = e.target.files[0]
      this.company_logo = file
      this.company_logo_url = URL.createObjectURL(file)
    },
    updateActivePackage(value) {
      let _this = this
      _this.active_package = value
    },

    updateWorkSpace() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"
        });

        // Init sweet alert
        let swal = this.$swal

        httpRequest.updateWorkspaceDetails(this.work_space_id, this.company_name, this.company_email, this.company_logo, this.active_package).then(function (response) {
          loader.hide()
          if (response.success) {
            swal(response.message);
          } else {
            swal(response.message);
          }
        })
      }
    },




  },
  async created() {
    await this.getWorkSpaceDetails()
  }

}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-4 mb-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Company</h4>
            <div class="p-2 w-100" style="max-width: 500px">
              <form class="form-horizontal" @submit.prevent="updateWorkSpace">

                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class=" ri-building-3-line auti-custom-input-icon customs-text-color"></i>
                  <label for="companyname">Company Name</label>
                  <input
                      type="text"
                      class="form-control"
                      id="companyname"
                      :readonly="!isRoleAccess(this.role, access_property, 'update')"
                      :style="{ 'background-color': isRoleAccess(this.role, access_property, 'update') ? 'white' : '#eee' }" 
                      :class="{ 'is-invalid': submitted && $v.company_name.$error }"
                      placeholder="Enter name"
                      v-model="company_name"/>
                  <div
                      v-if="submitted && !$v.company_name.required"
                      class="invalid-feedback">Company name is required.
                  </div>
                </div>

                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class="ri-mail-line auti-custom-input-icon customs-text-color"></i>
                  <label for="companyemail">Company Email</label>
                  <input
                      type="text"
                      class="form-control"
                      :readonly="!isRoleAccess(this.role, access_property, 'update')"
                      :style="{ 'background-color': isRoleAccess(this.role, access_property, 'update') ? 'white' : '#eee' }" 
                      id="companyemail"
                      :class="{ 'is-invalid': submitted && $v.company_email.$error }"
                      placeholder="Enter name"
                      v-model="company_email"/>
                  <div v-if="submitted && $v.company_email.$error" class="invalid-feedback">
                    <span v-if="!$v.company_email.required">Company email is required.</span>
                    <span v-if="!$v.company_email.email">Please enter valid email.</span>
                  </div>
                </div>

                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class=" ri-building-2-line auti-custom-input-icon customs-text-color"></i>
                  <label for="workspacename">Workspace Name</label>
                  <input
                      type="text"
                      class="form-control"
                      id="workspacename"
                      readonly
                      style="background-color: #eee;"
                      placeholder="Enter name"
                      v-model="workspace_name"/>
                </div>

                <div class="form-group auth-form-group-custom mb-3 mb-md-4"  >
                  <i class=" ri-building-2-line auti-custom-input-icon customs-text-color"></i>
                  <label for="workspaceurl">Workspace URL</label>
                  <input
                      type="text"
                      class="form-control"
                      id="workspaceurl"
                      readonly
                      style="background-color: #eee;"
                      v-model="org_domain"/>
                </div>

                <div class="form-group">
                  <b-row class="display-center">
                    <b-col cols="4">
                      <b-img v-if="company_logo_url"
                             :src="company_logo_url"
                             height="80"
                             alt="Responsive image"></b-img>
                    </b-col>
                    <b-col cols="8" v-if="isRoleAccess(this.role, this.access_property, 'update')">
                      <b-form-file id="file-large"
                                   placeholder="Select Logo"
                                   size="lg"
                                   type="file"
                                   accept=".jpg, .png"
                                   @change="onChange"></b-form-file>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="isRoleAccess(this.role, this.access_property, 'update')" class="mt-10 mt-md-10 text-right">
                  <b-button
                      class="customs-bg-color"
                      variant="primary"
                      size="lg"
                      type="submit"
                  >UPDATE <i class="ri-arrow-right-s-line" style="vertical-align:bottom"></i>
                  </b-button>
                </div>
              </form>
              <div class="mt-5">
                <b-row class="display-center customs-text-color font-weight-bold">
                  <b-col cols="12">
                    <div class="text-center">
                      YOU ARE USING FREE TRIAL
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.selected_package {
  border: 1px solid #f2a503;
  color: #111;
  background-color: #eff2f7;
}

.display-center {
  display: flex;
  align-items: center;
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.customs-text-color {
  color: #ff7c47;
}
</style>
